import React from 'react'
import Container    from '@mui/material/Container';
import Grid         from '@mui/material/Grid';
import Typography   from '@mui/material/Typography';

import './features.css';

function Features() {
  return (
    <div className="app__features">
        
      <Container maxWidth="lg" align="center">
        <div className="app__features-div">
          <Grid 
            container 
            spacing={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >

            <Grid item>
              <Typography variant="h2" align="center" color="#18314f" gutterBottom><i className="fa-solid fa-server"></i></Typography>
              <Typography variant="h5" gutterBottom>Hébergement</Typography>
              <Typography variant="body2">domaine DNS</Typography>
              <Typography variant="body2">web</Typography>
              <Typography variant="body2">mail</Typography>
              <Typography variant="body2">vps</Typography>
            </Grid>

            <Grid item>
              <Typography variant="h2" align="center" color="#4cb5ae" gutterBottom><i className="fa-solid fa-eye"></i></Typography>
              <Typography variant="h5" gutterBottom>Infogérance</Typography>
              <Typography variant="body2">supervision 24x7x365</Typography>
              <Typography variant="body2">astreinte monitoring 24x7</Typography>
              <Typography variant="body2">Sécurité</Typography>
              <Typography variant="body2">Sauvegarde quotidienne</Typography>
              <Typography variant="body2">Mise à jour en continu</Typography>
            </Grid>

            <Grid item>
              <Typography variant="h2" align="center" color="#6da34d" gutterBottom><i className="fa-solid fa-circle-question"></i> </Typography> 
              <Typography variant="h5" align="center" gutterBottom>Support</Typography>
              <Typography variant="body1" gutterBottom>du lun-vend 9h-22h</Typography>
            </Grid>
          </Grid>
              
        </div>
      </Container>
    </div>
  )
}

export default Features

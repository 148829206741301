import React from 'react'

import './header.css';
import appLogo from '../../assets/appLogo.png';

function Header() {
  return (
    <div className="app__header">
      <div className="app__header-logo">
        <img src={appLogo} alt="Logo Trefle" />
      </div>
      <div className="app__header-logo">
        <span><h1>TREFLE</h1></span>
      </div>
    </div>
  )
}

export default Header

import React from 'react'

import Box          from '@mui/material/Box';
import Card         from '@mui/material/Card';
import CardContent  from '@mui/material/CardContent';
import CardHeader   from '@mui/material/CardHeader';
import Grid         from '@mui/material/Grid';
import Typography   from '@mui/material/Typography';
import Container    from '@mui/material/Container';
// import GlobalStyles from '@mui/material/GlobalStyles';

import './highlights.css';
import tiers from './data';

let num2str = Intl.NumberFormat('fr-FR');

function HighLights() {
  return (
    <div className="app__highlights">
 
      <Container maxWidth="md" align="center" sx={{ pt: 8, pb: 6 }}>

        <Typography
          variant="h4"
          align="center"
          color="#fff"
          style={{ fontWeight: 600 }}
          gutterBottom
        >
          Nos offres
        </Typography>
        <Typography variant="h6" align="center" color="#b2baae" component="p">
          Contactez-nous pour obtenir nos meilleures offres.
        </Typography>
      </Container>

        
      <Container maxWidth="md" align="center">
        <Grid 
          container 
          spacing={4} 
          alignItems="flex-end"
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={7}
              sm={6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center', color: "#66b2ff" }}
                  action={tier.promo ? <i className="fa-regular fa-star"></i> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                    color: "#b2baae"
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
              
              {tier.promo ? <div className="ribbon"><Typography variant="h6">{num2str.format(tier.promo_price)}</Typography></div> : null}
              
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="#fff">
                      { tier.price? num2str.format(tier.price) : null}
                    </Typography>
                    <Typography variant="h6" color="#b2baae">
                      { tier.price ? '/' : null }
                      { tier.price ? tier.periode : null }
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
{/*
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
*/}
                </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
</div>

  );
}


export default HighLights

import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Paper } from '@mui/material';

import './services.css';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#f0f0f8',
  padding: theme.spacing(4),
  margin: theme.spacing(0),
  color: theme.palette.text.primary,
  boxShadow: 3,
}));


function Services() {
  return (
    <>
      <div className="app__services">
        <Typography
          marginTop={2}
          variant="h4"
          align="center"
          style={{ fontWeight: 600 }}
          gutterBottom
        >
          Nos Services
        </Typography>

        <div className="app__services-div">

          <Grid container spacing={6}>
              
            <Grid item md={3} sm={6} xs={12}>
              <div className="app__services-item">
              <h4>Hébergement</h4>
              <span>
                <p>Nom de domaine</p>
                <p>Web</p>
                <p>Messagerie</p>
              </span>
              </div>
            </Grid>
    
            <Grid item md={3} sm={6} xs={12}>
              <div className="app__services-item">
              <h4>Infogérance</h4>
              <span>
                <p>Serveur cloud</p>
                <p>Serveur privé</p>
                <p>Windows et Linux</p>
                <p>Réseau</p>
                <p>Firewall</p>
                <p>WiFi</p>
                <p>ToIP</p>
              </span>
              </div>
            </Grid>
              
            <Grid item md={3} sm={6} xs={12}>
              <div className="app__services-item">
              <h4>Expertise</h4>
              <span>
                <p>Conseil & Audit</p>
                <p>Assistance / Support</p>
                <p>Réseau et Sécurité</p>
                <p>Intégration</p>
                <p>Consolidation et virtualisation</p> 
                <p>(VMWare, Hyper-V, KVM)</p>
                <p>Sauvegarde</p> 
              </span>
              </div>
            </Grid>
              
            <Grid item md={3} sm={6} xs={12}>
              <div className="app__services-item">
                <Typography variant="inherit" component="h4">Système d'entreprise</Typography>
              <span>
                <Typography variant="inherit">Sécurité et contrôle d'accès</Typography>
                <Typography variant="inherit">Vidéo Surveillance</Typography>
                <Typography variant="inherit">Téléphonie VOIP/IPBX</Typography>
              </span>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="app__services-div">

          <Grid container spacing={8}>
            <Grid container item spacing={8}>
              <Grid item sm={6} key={1}>
                <Typography variant="h5">Infogérance</Typography>
              </Grid>
              <Grid item sm={6}key={2}>
                <Item>
                  <Typography variant="body2" align="justify">
                    Confiez-nous la gestion de vos serveurs pour vous concentrer sereinement sur votre coeur de métier et le développement de votre entreprise.
                  </Typography>
                  <Typography variant="body2" align="justify">
                    Nous nous occupons de vos infrastructures.
                    Nous disposons de consultants confirmés et vous fournissons un service de qualité conforme aux meilleures pratiques.
                  </Typography>
                  <Typography variant="body2">
                    <li>Maintien en conditions opérationnelles</li>
                    <li>Assurer un haut niveau de disponibilité</li>
                    <li>optimiser la sécurité</li>
                    <li>accompagner votre entreprise dans sa transformation digitale</li>
                  </Typography>
                </Item>
              </Grid>
            </Grid>

            <Grid container item direction="row-reverse" spacing={8}>
              <Grid item sm={6} key={3}>
                <Typography variant="h5">Support informatique</Typography>
              </Grid>
              <Grid item sm={6} key={8}>
                <Item>
                <Typography variant="body2">Support à vos équipes IT interne (mail, helpdesk)</Typography>
                <Typography variant="body2">Intervention à distance</Typography>
                <Typography variant="body2">Administration quotidienne des infrastructure</Typography>
                <Typography variant="body2">Gestion des sauvegardes et restaurations</Typography>
                <Typography variant="body2">Maintenances préventives</Typography>
                <Typography variant="body2">Renouvellement de licences</Typography>
                </Item>
              </Grid>
            </Grid>

            <Grid container item spacing={8}>
              <Grid item sm={6}>
              <Typography variant="h5">Supervision</Typography>
              </Grid>
              <Grid item sm={6}>
                <Item>
                  <Typography variant="body2" align="justify">
                    Vos serveurs font l'objet d'une supervision 24x7x365 et nous recevons une alerte dès qu'un défaut survient. Il est pris en charge immédiatement afin de remettre le service en fonction au plus vite.
                    La supervision permet aussi d'anticiper les évolutions nécessaires sur le matériel.
                  </Typography>
                  <Typography variant="body2" align="justify">
                    Les données de vos serveurs sont sauvegardées quotidiennement avec un historique de 5 à 10 jours. Ces sauvegardes peuvent être restaurées sur demande.
                  </Typography>
                </Item>
              </Grid>
            </Grid>

            <Grid container item spacing={8} direction="row-reverse">
              <Grid item sm={6}>
                <Typography variant="h5">Etudes et Conseils</Typography>
              </Grid>      
              <Grid item sm={6}>
                <Item>
                <Typography variant="body2">Elaboration de cahier des charges</Typography>
                <Typography variant="body2">Diagnostic</Typography>
                <Typography variant="body2">Continuité et reprise d'activités</Typography>
                <Typography variant="body2">Mise en conformité</Typography>
                </Item>
              </Grid>
            </Grid>

            <Grid container item spacing={8}>
              <Grid item sm={6}>
              <Typography variant="h5">Virtualisation</Typography>
              </Grid>
              <Grid item sm={6}>
                <Item>
                <Typography variant="body2">Windows et Linux</Typography>
                <Typography variant="body2">VMWare</Typography>
                <Typography variant="body2">Hyper-V</Typography>
                <Typography variant="body2">KVM</Typography>
                <Typography variant="body2">Containers</Typography>
                </Item>
              </Grid>
            </Grid>
              
            <Grid container item spacing={8} direction="row-reverse">
              <Grid item sm={6}>
                <Typography variant="h5">Vidéosurveillance et Contrôle d'accès</Typography>
              </Grid>     
              <Grid item sm={6}>
                <Item>
                  <Typography variant="body2">Choix des caméras</Typography>
                  <Typography variant="body2">
                    <li>positionnement</li>
                    <li>type</li>
                    <li>installation</li>
                  </Typography>
                  <Typography variant="body2">Régie vidéo / poste de contrôle</Typography>
                  <Typography variant="body2">Enregistreur DVR, NVR</Typography>
                  <Typography variant="body2">Optimisation</Typography>
                  <Typography variant="body2">Maintenance</Typography>
                </Item>
              </Grid>
            </Grid>

          </Grid>
        </div>

      </div>
    </>
  )
}

export default Services

import React from 'react'
import Typography   from '@mui/material/Typography';
import Grid   from '@mui/material/Grid';
import Stack   from '@mui/material/Stack';

import appLogo from '../../assets/appLogo.png';

import './footer.css';

function Footer() {
  return (
    <div className="app__footer">
      <div className="app__footer-links">
        <div className="app__footer-links_div">
          <Stack alignItems="center">

            <Grid container justifyContent="center" spacing={1}>
              <Grid item alignItem="center">
                <img src={appLogo} alt="logo" />
              </Grid>
              <Grid item alignItem="center">
                <Typography  variant="h5" style={{ fontWeight: 600 }}>
                  TREFLE
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="body2"><i className="fa fa-map-marker"></i>  24 rue Marchand, Dakar</Typography>
            <Typography variant="body2"><i className="fa fa-phone"></i> +221 70511 1010</Typography>
            <Typography variant="body2"><i className="fa fa-envelope"></i> trefle@ittrefle.com</Typography>
            <ul className="social">
            <li id="facebook"><a href="https://facebook.com/treflecie"><i className="fa-brands fa-facebook-f"></i></a></li>
            <li id="linkedin"><a href="https://www.linkedin.com/company/trefle"><i className="fa-brands fa-linkedin-in"></i></a></li>
          </ul>
          </Stack>


        </div>
      </div>

      <div className="app__footer-copyright">
        <Typography variant="inherit">© 2022 TREFLE Sarl - Tous droits réservés.</Typography>
      </div>
    </div>
  )
}

export default Footer

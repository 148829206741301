import React from 'react';

import './app.css';

import { Header, Content, Services, Features, Footer, Clients, HighLights, Hosting, DomUserForm } from './containers';
import { Brand, Navbar } from './components';
import { CssBaseline } from '@mui/material';

function App() {
  return (
    <>
        <CssBaseline />

        <div className='gradient__bg'>
          <Navbar />
          <Header />
        </div>
        <Brand />
        <Content />
        <HighLights />
        {/* <Services /> */}
        {/* <Hosting /> */}
        {/* <DomUserForm /> */}
        <Features />
        <Clients />
        <Footer />
    </>
  )
}

export default App


import React from 'react'
import './clients.css';

import bdm from './bdm-logo.png';
import dpworld from './dpworld-logo.png';
import iata from './iata-logo-transp.png';
import cesag from './cesag-logo.jpeg';
import arc from './arc-logo.png';
import biennale from './biennale-logo.jpg';
import { Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const Img = styled('img')({
  margin: 'auto',
  width: "150px",
  height: "150px"
});

const Div = styled('div')({
  margin: '2rem 0'
});


function Clients() {
  return (
    <Div>
      <Typography variant="h4" align="center" gutterBottom>Ils nous font confiance.</Typography>
      <Grid 
        container
        spacing={4} 
        direction="row" 
        alignItems="center" 
        justifyContent="center"
        mt={2}
      >
        <Grid item>
          <Paper elevation={1}>
            <Img src={arc} alt="arc_logo" />
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={1}>
            <Img src={bdm} alt="bdm_logo" />
          </Paper>
        </Grid>        
        <Grid item>
          <Paper elevation={3}>
            <div>
              <Img src={biennale} alt="biennale_logo" />
              </div>
            </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <Img src={cesag} alt="cesag_logo" />
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={4}>
              <Img src={dpworld} alt="dpworld_logo" />
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3}>
            <div>
              <Img src={iata} alt="iata_logo" />
              </div>
            </Paper>
        </Grid>
      </Grid>
    </Div>
  )
}

export default Clients

import React from 'react'
import Typography   from '@mui/material/Typography';

import './content.css';

function Content() {
   return (
      <div>
          <div className="app__content">
              <Typography variant="h2">Votre partenaire pour un service IT en toute sérénité.</Typography>
              <Typography variant="h5"> Confiez-nous l'infogérance de vos serveurs et Réseaux</Typography>
          </div>
      </div>    
)
}

export default Content

import React from 'react'
import './comingsoon.css';

import watch from '../../assets/computer.jpeg';

function ComingSoon() {
  return (
        <div className="app__content">                 
                <div className="left-section">
                        <img src={watch} alt="watch"/>
                </div>
                <div className="right-section">
                        <div className="main-content">
                                <h2>Notre site est en construction.</h2>
                        </div>
                        <div className='main-content' style={{color: "#242f24", fontFamily: 'Manrope', align: 'center' }}>
                                <p>Infrastructure Système Windows & Linux - Virtualisation VMWare, KVM, HyperV - Réseau et télécom</p>
                                <p> Infogérance - Hébergement et Noms de domaine</p>
                        </div>
                        <div>
                                <ul className="social">
                                        <li id="facebook"><a href="https://facebook.com/treflecie"><i className="fa fa-facebook"></i></a></li>
                                        <li id="linkedin"><a href="https://www.linkedin.com/company/trefle"><i className="fa fa-linkedin"></i></a></li>
                                </ul>
                        </div>
                </div>
        </div>    
  )
}

export default ComingSoon

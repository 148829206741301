
const tiers = [
    {
      title: 'Nom de Domaine .SN',
      subheader: '',
      price: 13500*1.18,
      periode: 'an',
      description: [
        'votre nom de domaine .sn',
        'gestion de vos enregistrements',
        'Support standard',
      ],
      buttonText: 'Get started',
      buttonVariant: 'contained',
      promo: 1,
      promo_price: 12000*1.18,
    },
    {
      title: 'Infogérance',
      subheader: '',
    //   price: 59500*1.18,
      periode: 'mois',
      description: [
        'Conseil',
        'Installation et configuration',
        'Sécurité',
        'Mises à jour',
        'Sauvegarde',
        'Supervision et Monitoring',
        // 'Un interlocuteur unique',
      ],
      buttonText: 'En savoir plus',
      buttonVariant: 'contained',
      promo: 0,
    }
  ];
  
export default tiers;
import React from 'react'

import Box          from '@mui/material/Box';
import Card         from '@mui/material/Card';
import CardContent  from '@mui/material/CardContent';
import CardHeader   from '@mui/material/CardHeader';
import Grid         from '@mui/material/Grid';
import Typography   from '@mui/material/Typography';
import Container    from '@mui/material/Container';
// import GlobalStyles from '@mui/material/GlobalStyles';

import './hosting.css';
import { tiers, boxColors } from './datahostings';

let num2str = Intl.NumberFormat('fr-FR');

function Hosting() {
  return (
    <div className="app__hosting">

      <Container maxWidth="md" align="center" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="#fff"
          style={{ fontWeight: 600 }}
          gutterBottom
        >
          Hébergements
        </Typography>
        <Typography variant="h6" align="center" color="#b2baae" component="p">
          Nos offres d'hébergement web.
        </Typography>
      </Container>

      <Container maxWidth="md" component="main" align="center">
        <Grid 
          container 
          spacing={2} 
          alignItems="flex-end"
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {tiers.map((tier, index) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              xs={7}
              sm={5}
              md={3}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center', color: "#fff" }}
                  action={tier.promo ? <i className="fa-regular fa-star"></i> : null}
                  subheaderTypographyProps={{
                    align: 'center',
                    color: "#b2baae"
                  }}
                  sx={{
                    backgroundColor: boxColors[index%4].color1,
                  }}
                />
                <CardContent
                    sx={{
                      backgroundColor: boxColors[index%4].color2,
                    }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                      // backgroundColor: '#8b74ba',
                    }}
                  >
                    <Typography component="h2" variant="h3" color="#fff">
                      { tier.price? num2str.format(tier.price) : null }
                    </Typography>
                    <Typography variant="h6" color="#b2baae">
                      { tier.price ? '/' : null }
                      { tier.price ? tier.periode : null }
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
{/*
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
*/}
                </Card>
            </Grid>
          ))}
        </Grid>
      </Container>


</div>

  );
}

export default Hosting
